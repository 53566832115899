.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.bg-light-blue {
		background-color: var(--color-hol-orange-light);
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				color: var(--color-hol-orange);
				margin-top: 20px;
				width: 45px;
				height: 45px;
				display: inline-block;
				background-size: contain;
				background-repeat: no-repeat;
				filter: invert(59%) sepia(37%) saturate(7275%) hue-rotate(9deg) brightness(95%) contrast(102%);

				&.icon-headset {
					background-image: url(RESOURCE/img/icon-headset.svg);
				}

				&.icon-house-building {
					background-image: url(RESOURCE/img/icon-house-building.svg);
				}

				&.icon-person-walking-luggage {
					background-image: url(RESOURCE/img/icon-person-walking-luggage.svg);
				}
			}

			.title {
				color: var(--color-hol-orange);
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
				text-transform: uppercase;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;
				color: var(--color-hol-green);

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}