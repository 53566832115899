/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: "NeueKabelProBold";
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/kabel-bold/font.woff2') format('woff2'),
		url('RESOURCE/fonts/kabel-bold/font.woff') format('woff');

	font-display: swap;
}

@font-face {
	font-family: "NeueKabelProRegular";
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/kabel-regular/font.woff2') format('woff2'),
		url('RESOURCE/fonts/kabel-regular/font.woff') format('woff');

	font-display: swap;
}

@font-face {
	font-family: "NeueKabelProLight";
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/kabel-light/font.woff2') format('woff2'),
		url('RESOURCE/fonts/kabel-light/font.woff') format('woff');

	font-display: swap;
}