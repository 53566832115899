.footer-v1 {
	.input-group {
		.form-control {
			&::placeholder {
				font-size: 12px !important;
			}
		}
	}

	.swiss-travel {
		background-color: #fff;
		padding: 5px;
		border-radius: 6px;
		max-width: 160px;
	}

	.list-icon {
		li {
			padding-left: 25px;
			position: relative;
			margin-bottom: .5rem;
			color: var(--color-hol-beige);

			i {
				position: absolute;
				left: 0;
				top: 6px;

				&.fa-building:before {

					width: 12px;
					height: 16px;
					display: inline-block;
					filter: invert(1);
					background-size: contain;
				}
			}
		}
	}

	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-hol-beige);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.v-office-logo {

			background-color: var(--color-secondary);
			padding: 10px 20px;

			@media(min-width: 993px) {
				text-align: right;
				padding: 10px 60px;
			}

			@media(max-width: 992px) {
				text-align: left;
			}

			/*img {
				filter: brightness(0) saturate(100%) invert(0%) sepia(97%) saturate(18%) hue-rotate(119deg) brightness(95%) contrast(101%);
			}

			.ls-is-cached {
				filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);
			}*/
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa,
				.fa-brands {
					font-size: 36px;
					color: var(--footer-font-color);

				}
			}

		}

		.contact-icons {

			i {
				color: var(--footer-font-color);

			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;

			.fa-paper-plane {
				color: var(--color-white);
			}
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}
	}


}